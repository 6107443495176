<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="70vw"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}图层</div>

        <div class="content" v-loading="loading">
            <el-form class="column3" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="图层目录" prop="layerCatalogId" ref="layerCatalogId">
                    <el-cascader
                        v-model="form.layerCatalogId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'catalogName',value:'id' }"
                        @change="changeLayerCatalogId"></el-cascader>
                </el-form-item>

                <el-form-item label="图层名称" prop="layerName">
                    <el-input v-model="form.layerName" placeholder="请输入图层名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="图层类型" prop="layerType">
                    <Dictionary v-model="form.layerType" valKey="val" code="LAYERTYPE"></Dictionary>
                </el-form-item>

                <el-form-item label="图层配置" prop="json" style="width:100%;">
                    <CodeEdit v-model="form.json" @change="changeJson"></CodeEdit>
                </el-form-item>

                <el-form-item label="图例" prop="legendFile" style="width:100%;">
                    <UploadImages v-model="form.legendFile"></UploadImages>
                </el-form-item>


            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'
import {initTreeData} from "@/util/common";
export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        CodeEdit: () => import("@/components/CodeEdit.vue"),
        UploadImages: () => import("@/components/upload/Images.vue"),
    },
    mixins: [mixinsForm],
    props: {
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/layer'].save,
            },
            treeselectOptions:[],
            rules: {
                layerCatalogId: [
                    {required: true, message: '请选择图层目录', trigger: ['blur','change']},
                ],
                layerName: [
                    {required: true, message: '请填写图层名称', trigger: ['blur','change']},
                ],
                layerType: [
                    {required: true, message: '请选择图层类型', trigger: ['blur','change']},
                ],
                json: [
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(value){
                                try {
                                    var json = JSON.parse(value);
                                }catch (err){
                                    callback(new Error('图层配置填写错误'));
                                };
                            };
                            callback();
                        }
                    },
                ],
            },
        };
    },
    computed: {},
    watch: {
        listData:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal){
                this.treeselectOptions = initTreeData(newVal);
            }
        },
    },
    methods: {
        changeLayerCatalogId(e){
            this.$refs.form.validateField('layerCatalogId');
        },
        changeJson(e){
            this.$refs.form.validateField('json');
        },
        submit(){
            var form = this.form.deepClone();
            try {
                form.legendFile = JSON.stringify(this.form.legendFile);
            }catch (err){
                console.log(err);
            };
            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    this.submitLoading = true;

                    this.api.save(form).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
